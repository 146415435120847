
html{
  height: 100%;
}

body{ 
  height: 100%; 
  background-image: url(https://images.unsplash.com/photo-1571210059434-edf0dc48e414?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjI0MX0&auto=format&fit=crop&w=1650&q=80);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

header{
  height: auto;
  width: 60%;
  max-width: 400px;
  text-align: center;
  color: white;
  margin: 5% auto;
  padding: 30px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 5px;
}

.profile-pic {
  height: 140px;
  width: 140px;
  border-radius: 30%;
}

.icon {
  font-size: 5em;
  color: #fff;
  margin: 10px;
}

.blank {
  height: 60vh;
}

@media screen and (max-width: 450px) {
  .icon{
    font-size: 3em;
  }
}
