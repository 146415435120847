.prosjekt-container {
    color: white;
    width: 60%;
    max-width: 400px;
    color: white;
    margin: 5% auto;
    padding: 30px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
}

.button {
    background-color: #555555;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 1vw;
}

.icon{
    color: #ddd;
}

.icon:hover {
    color: #fff;
}